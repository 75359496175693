<template>
  <div>
    <el-card shadow="never" style="height: 100%" body-style="height: 100%">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col
            ><el-row type="flex" justify="start"
              ><h4>Iniciar viagem</h4></el-row
            ></el-col
          >
        </el-row>
      </template>
      <el-empty
        v-show="!showStage"
        :description="
          userIsDriver
            ? 'Escaneie o QRCode do veículo para iniciar'
            : 'Você não é um motorista, registre este controle em Frota > Controles'
        "
        image="/images/qrcode.png"
      >
        <el-button
          :disabled="!userIsDriver"
          type="primary"
          @click="showStage = 'readQr'"
          >Escanear QRCode</el-button
        >
      </el-empty>
      <div v-if="showStage === 'readQr'">
        <qrcode-stream
          :key="qrCodeReaderUid"
          :track="paintOutline"
          @decode="onDecode"
          @init="onInit"
        ></qrcode-stream>
        <el-row type="flex" justify="center">
          <h4 style="text-align: center">
            Segure a câmera em frente ao código QR do veículo
          </h4>
        </el-row>
      </div>

      <div v-show="showStage === 'carInfo'">
        <el-result
          v-show="hasError"
          icon="error"
          title="Veículo não encontrado"
          subTitle="O QRCode que vocẽ escaneou está inválido ou o veículo foi removido no sistema."
        >
          <template #extra>
            <el-button type="primary" @click="showStage = null" size="medium"
              >Voltar</el-button
            >
          </template>
        </el-result>
        <el-descriptions :column="1" border v-show="!hasError">
          <el-descriptions-item>
            <template #label> Veículo </template>
            {{ car?.model }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Placa </template>
            {{ car?.pane }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Último motorista </template>
            {{ car?.lastControl?.driver?.employee?.name || "desconhecido" }}
          </el-descriptions-item>
        </el-descriptions>
        <el-row
          v-show="!hasError"
          type="flex"
          justify="center"
          style="margin-top: 50%"
        >
          <el-button
            v-if="lastCarControlWasNotCompleted() && itsMyOwnRegister()"
            @click="() => finalizeRegister()"
            type="primary"
            >Finalizar movimentação</el-button
          >
          <el-button v-else @click="() => initiateRegister()" type="primary"
            >Registrar movimentação</el-button
          >
        </el-row>
      </div>
      <div v-if="showStage === 'finalizeRegister'">
        <base-input
          v-model="car_control.odometer_end"
          label="Odometro final:"
          type="money"
          :minimumFractionDigits="0"
        ></base-input>
        <base-input
          type="textarea"
          label="Observações"
          v-model="car_control.obs"
          hint="Digite ocorrências da viagem como: Lâmpada de freio queimou, veículo apresentando curto na luz de seta..."
        ></base-input>
        <el-button @click="() => save()" type="primary"
          >Finalizar e salvar</el-button
        >
      </div>
      <div v-if="showStage === 'selfie'">
        <el-row type="flex" justify="center"
          ><h4>Confirme quem é você</h4></el-row
        >
        <div class="border-button">
          <el-row type="flex" justify="center">
            <div style="height: 450px">
              <div v-show="isCameraOpen" class="camera-canvas">
                <video
                  ref="camera"
                  :width="canvasWidth"
                  :height="canvasHeight"
                  autoplay
                ></video>
                <canvas
                  v-show="false"
                  id="photoTaken"
                  ref="canvas"
                  :width="canvasWidth"
                  :height="canvasHeight"
                ></canvas>
              </div>
            </div>
          </el-row>
        </div>
        <el-row type="flex" justify="center">
          <el-button
            type="primary"
            size="large"
            icon="el-icon-camera"
            @click="() => capture('odometer')"
            >Enviar foto</el-button
          >
        </el-row>
      </div>
      <div v-if="showStage === 'odometer'">
        <el-row type="flex" justify="center"
          ><h4>Envie foto do odometro do veículo</h4></el-row
        >
        <div class="border-button">
          <el-row type="flex" justify="center">
            <div style="height: 450px">
              <div v-show="isCameraOpen" class="camera-canvas">
                <video
                  ref="camera"
                  :width="canvasWidth"
                  :height="canvasHeight"
                  autoplay
                ></video>
                <canvas
                  v-show="false"
                  id="photoTaken"
                  ref="canvas"
                  :width="canvasWidth"
                  :height="canvasHeight"
                ></canvas>
              </div>
            </div>
          </el-row>
        </div>
        <el-row type="flex" justify="center">
          <el-button
            type="primary"
            size="large"
            icon="el-icon-camera"
            @click="() => capture('car_controlInfo')"
            >Enviar foto</el-button
          >
        </el-row>
      </div>
      <div v-show="showStage === 'car_controlInfo'">
        <el-row typ="flex" justify="center">
          <h4>Preencha os dados do</h4>
        </el-row>
        <base-input
          v-model="car_control.odometer_start"
          type="money"
          :minimumFractionDigits="0"
          label="Odômetro:"
        ></base-input>

        <h4>Iniciando em: {{ dateFormatterLong.format(today) }}</h4>

        <el-row type="flex" justify="center" style="margin-top: 16px">
          <el-button
            type="primary"
            size="large"
            icon="el-icon-upload"
            @click="save"
            >Registrar saída</el-button
          >
        </el-row>
      </div>
      <el-result
        v-show="showStage === 'complete'"
        icon="success"
        title="Tudo Ok!"
        subTitle="Registro de bordo criado com sucesso"
      >
        <template #extra>
          <el-button type="primary" @click="showStage = null" size="medium"
            >Voltar</el-button
          >
        </template>
      </el-result>
      <el-result
        v-show="showStage === 'error'"
        icon="error"
        title="Ocorreu um erro!"
        subTitle="Algo não funcionou adequadamente, por favor tente novamente."
      >
        <template #extra>
          <el-button type="primary" @click="showStage = null" size="medium"
            >Voltar</el-button
          >
        </template>
      </el-result>
    </el-card>
    <!--<el-affix target="#app" position="bottom" v-show="showStage">
      <el-button
        @click="backStage"
        type="primary"
        icon="el-icon-arrow-left"
        size="large"
      ></el-button>
    </el-affix>-->
  </div>
</template>

<script>
//import Moment from "moment";
import BaseInput from "../components/BaseInput.vue";
import { QrcodeStream } from "vue3-qrcode-reader";
import { uuid } from "vue-uuid";
import {
  notifySuccess,
  notifyError,
  messageOnCenter,
} from "../utils/notifiers";
import CarService from "../services/cars";
import DriverService from "../services/drivers";
import {
  currencyFormatter,
  numberFormatter,
  dateFormatter,
  dateFormatterLong,
} from "../utils/formatters";
//import { camera } from "vue-capture";
export default {
  name: "BillsPage",
  components: {
    QrcodeStream,
    //camera,
    BaseInput,
  },
  data: () => ({
    canvasHeight: 450,
    canvasWidth: 400,
    hasError: false,
    qrCodeReaderUid: uuid.v4(),
    car: null,
    driver: null,
    car_control: {},
    today: new Date(),
    loading: null,
    showStage: null,
    images: [],
    currencyFormatter: currencyFormatter,
    numberFormat: numberFormatter,
    dateFormatter: dateFormatter,
    dateFormatterLong: dateFormatterLong,
  }),
  computed: {
    isCameraOpen() {
      const showCamera =
        this.showStage === "selfie" || this.showStage === "odometer";
      if (showCamera)
        this.startCameraStream(
          this.showStage === "selfie" ? "user" : "environment"
        );
      else this.stopCameraStream();
      return showCamera;
    },
    Data() {
      return JSON.parse(this.$route?.query?.data || "{}");
    },
  },
  mounted() {
    if (this.$route.params.uid?.length) this.onDecode(this.$route.params.uid);
  },
  methods: {
    async findDriver(driverId) {
      const { driver } = await DriverService(driverId).get();

      if (driver) this.driver = driver;
    },
    finalizeRegister() {
      this.showStage = "finalizeRegister";
    },
    initiateRegister() {
      if (this.lastCarControlWasNotCompleted() && !this.itsMyOwnRegister())
        messageOnCenter(
          "Existe um registro em aberto para este veículo",
          "Ao cadastrar um novo registro, o sistema automaticamente fechará o que está em aberto"
        );
      if (this.lastDriverControlWasNotCompleted())
        messageOnCenter(
          "Existe um registro em aberto para você em outro veículo",
          "Ao cadastrar um novo registro, o sistema automaticamente fechará o que está em aberto"
        );

      this.showStage = "selfie";
    },
    itsMyOwnRegister() {
      return (
        this.$store.state.user?.driver?.uid ===
        this.car?.lastControl?.driver?.uid
      );
    },
    startCameraStream(mode = "user") {
      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: mode,
        },
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.showStage =
            this.showStage === "selfie" ? "odometer" : "car_controlInfo";
          this.$message.error("O navegador não suporta acesso à câmera");
        });
    },
    capture(nextStage) {
      const FLASH_TIMEOUT = 50;
      let self = this;
      setTimeout(() => {
        const context = self.$refs.canvas.getContext("2d");
        context.drawImage(
          self.$refs.camera,
          0,
          0,
          self.canvasWidth,
          self.canvasHeight
        );
        const dataUrl = self.$refs.canvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");

        self.images.push(dataUrl);
        this.stopCameraStream();
        self.showStage = nextStage;
      }, FLASH_TIMEOUT);
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    },
    backStage() {
      this.showStage = null;
    },
    async updateAndFinalizeControl(_control) {
      if (_control?.uid) {
        const { control, error } = await CarService(this.car?.uid)
          .Control(_control.uid)
          .update({
            complete_at: new Date(),
            ...this.car_control,
          });

        if (control) {
          notifySuccess(control.message);

          if (this.$route.params.uid?.length) this.$router.push("/");
        } else notifyError(error.message);

        this.showStage = "complete";
        this.closeLoading();
      }
    },
    shouldUpdateControl() {
      return this.car?.lastControl && !this.car?.lastControl.complete_at;
    },
    async save() {
      this.showLoading("Enviando informações...");

      if (this.shouldUpdateControl())
        return this.updateAndFinalizeControl(this.car?.lastControl);

      const { control, error } = await CarService(this.car?.uid)
        .Control()
        .create({
          ...this.car_control,
          ...this.coordinates,
          driver_id: this.$store.state.user.driver?.uid,
          car_id: this.car?.uid,
          image_selfie: this.images[0],
          image_odometer: this.images[1],
          start_at: this.today,
          category: "Viagem",
        });

      if (control) notifySuccess(control.message);
      else notifyError(error.message);

      this.showStage = "complete";

      this.closeLoading();
    },
    async onInit(v) {
      try {
        this.showLoading("Abrindo a câmera...");
        await v;
      } catch (e) {
        this.showStage = null;
        let message = "";

        switch (e.name) {
          case "NotAllowedError":
            message = "Você precisa aceitar a utilização da câmera";
            break;
          case "NotFoundError":
            message = "A câmera não foi detectada";
            break;
          case "NotSupportedError":
          case "InsecureContextError":
            message = "Você deve acessar o site por HTTPS";
            break;
          case "NotReadableError":
            message = "A câmera está ocupada";
            break;
          case "OverconstrainedError":
            message = "A câmera não foi detectada";
            break;
          case "StreamApiNotSupportedError":
            message = "Este navegador não suporta acesso à câmera";
            break;
          default:
            message = "Não foi possível abrir a camera para escanear";
        }

        this.$message.error(message);
      } finally {
        const self = this;
        setTimeout(() => {
          self.closeLoading();
        }, 1000);
      }
    },
    async getCurrentPosition() {
      if ("geolocation" in navigator) {
        const self = this;
        navigator.geolocation.getCurrentPosition((position) => {
          self.coordinates = position.coords;
        });
      } else this.$message.error("Seu navegador não suporta geolocalização");
    },
    paintOutline(detectedCodes, ctx) {
      const {
        bottomLeftCorner,
        bottomRightCorner,
        topRightCorner,
        topLeftCorner,
      } = detectedCodes;

      makeAnSquare(
        [bottomLeftCorner, topLeftCorner, topRightCorner, bottomRightCorner],
        ctx
      );
    },
    onDecode(d) {
      if (isUuid(d)) {
        this.showLoading("Buscando o veículo");

        this.fetchCar(d);
      } else this.showStage = "error";
    },
    userIsDriver() {
      return !!this.$store.state.user?.driver?.uid;
    },
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    showLoading(message = "Carregando... Aguarde.") {
      this.loading = this.$loading({
        fullscreen: true,
        lock: true,
        text: message,
      });
    },
    closeLoading() {
      if (this.loading) this.loading.close();
    },
    lastCarControlWasNotCompleted() {
      return !this.car?.lastControl?.complete_at;
    },
    lastDriverControlWasNotCompleted() {
      return !this.driver?.lastControl?.complete_at;
    },
    async fetchCar(uid) {
      if (uid) {
        this.showLoading("Buscando informações do veículo...");

        const { car } = await CarService(uid).get();

        if (car) this.car = car;
        else this.hasError = true;

        this.showStage = "carInfo";
        this.closeLoading();
      }
    },
  },
};
function makeAnSquare(points, ctx) {
  ctx.lineWidth = 2;
  ctx.strokeStyle = "#007bff";
  ctx.beginPath();
  const firstPoint = points.pop();

  ctx.moveTo(firstPoint.x, firstPoint.y);

  for (const { x, y } of points) {
    ctx.lineTo(x, y);
  }

  ctx.lineTo(firstPoint.x, firstPoint.y);
  ctx.closePath();
  ctx.stroke();
}
const isUuid = (uuid) =>
  new RegExp(
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  ).test(`${uuid}`);
</script>
<style>
body {
  -webkit-print-color-adjust: exact;
}
.el-card {
  width: 100%;
}
.mt-2 {
  margin-top: 4px;
}
.p-0 {
  padding: 0px !important;
}
.border-button {
  border: 1px solid var(--el-border-color-base);
  border-radius: 0;
  padding: 4px;
}
.text-red {
  color: red;
}
.m-0 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.text-center {
  text-align: center !important;
}
@media print {
  table.el-table__body {
    width: auto !important;
  }
  table.el-table__header {
    width: auto !important;
  }
  div.md-12-print {
    max-width: 49% !important;
  }
  div.md-8-print {
    max-width: 33% !important;
  }
}
.el-icon-camera-solid {
  color: #dcdde0;
}
.cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  width: 100%;
  justify-content: center;
}
.el-empty__image {
  opacity: 0.3;
}
</style>